import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'components/Layout';
import { HeroWithMediaData } from 'components/blocks/HeroBanner';
import HeroWithMedia from 'components/blocks/HeroBanner/HeroWithMedia';
import { ExpandableRelatedContentCardsWithInPageNav } from 'components/blocks/RelatedContent';
import { ExpandableRelatedContentCardsWithInAppNavProps } from 'components/blocks/RelatedContent/ExpandableRelatedContentCardsWithInPageNav';
import mapBlocks from 'helpers/blockMappers/mapBlocks';
import { CsBlock } from 'helpers/blockMappers/mappingUtils';
import { processPageMeta } from 'helpers/csTypeProcessors';
import { CsEntryNode, PageMeta } from 'types/contentStack';

type Props = {
  data: TemplateFPageData;
};

const TemplateFPage: React.FC<Props> = ({ data }) => {
  const page = data.csTemplateF;
  const { meta, breadcrumbs } = processPageMeta(
    page.page_meta,
    page.url,
    data.allCsTemplateF.nodes
  );

  return (
    <Layout
      url={page.url}
      pageMetadata={meta}
      breadcrumbs={breadcrumbs}
      locale={page.locale}>
      <HeroWithMedia
        hero_with_media={page.hero_with_media}
        showResponsiveVersion={page.show_new_hero}
      />
      <ExpandableRelatedContentCardsWithInPageNav
        in_page_navigation_title={
          page.related_content_cards_with_in_page_navigation.in_page_navigation_title
        }
        sections={page.related_content_cards_with_in_page_navigation.sections}
      />
      {page.blocks &&
        mapBlocks(page.blocks, { pageMeta: page.page_meta, pageUrl: page.url })}
    </Layout>
  );
};

export default TemplateFPage;

type TemplateFPageData = {
  csTemplateF: {
    title: string;
    url: string;
    locale: string;
    show_new_hero: boolean;
    hero_with_media: HeroWithMediaData;
    related_content_cards_with_in_page_navigation: ExpandableRelatedContentCardsWithInAppNavProps;
    blocks: CsBlock[];
    page_meta: PageMeta;
  };
  allCsTemplateF: {
    nodes: CsEntryNode[];
  };
};

export const query = graphql`
  query($id: String!, $uid: String!) {
    csTemplateF(id: { eq: $id }) {
      title
      url
      locale
      show_new_hero
      hero_with_media {
        ...HeroWithMedia
      }
      related_content_cards_with_in_page_navigation {
        ...ExpandableRelatedContentCardsWithInPageNav
      }
      blocks {
        cta_banners {
          ...CtaBannersBlock
        }
        expandable_related_content_cards {
          ...ExpandableRelatedContentCardsBlock
        }
        feature {
          ...FeatureBlock
        }
        info_cards {
          ...InfoCardsBlock
        }
        related_content_card_list_with_pagination {
          ...RelatedContentCardListWithPaginationBlock
        }
        rich_text {
          ...RichTextBlock
        }
      }
      page_meta {
        ...PageMeta
      }
    }
    allCsTemplateF(filter: { uid: { eq: $uid } }) {
      nodes {
        locale
        page_meta {
          canonical_tag
        }
      }
    }
  }
`;
